import React from 'react';
import { Bubble } from 'react-chartjs-2';
import randomColor from 'randomcolor';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Registrar componentes necesarios
ChartJS.register(
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  ChartDataLabels
);

const BubbleChart = ({ data }) => {
  // Función para agrupar los datos por sección
  const processData = (bubbleData) => {
    // Agrupamos los datos por sección
    const groupedData = bubbleData.reduce((acc, item) => {
      if (!acc[item.section]) {
        acc[item.section] = {
          labels: [],
          data: [],
        };
      }

      // Agregar el elemento a la lista de etiquetas (evitar duplicados)
      if (!acc[item.section].labels.includes(item.element)) {
        acc[item.section].labels.push(item.element);
      }

      // Agregar el punto de datos
      acc[item.section].data.push({
        x: item.x,
        y: item.y,
        r: item.r,
        label: item.element,
      });

      return acc;
    }, {});

    // Crear el dataset para cada sección
    const bubbleDatasets = Object.keys(groupedData).map((section) => ({
      label: section,
      data: groupedData[section].data,
      backgroundColor:
        section === "General"
          ? "rgba(192, 8, 0, 1)"
          : randomColor({ luminosity: "light", format: "rgba", alpha: 0.5 }),
      borderColor:
        section === "General"
          ? "rgba(192, 8, 0, 1)"
          : randomColor({ luminosity: "dark", format: "rgba", alpha: 1 }),
      borderWidth: 1,
    }));

    return bubbleDatasets;
  };

  // Configuración del gráfico
  const chartData = {
    datasets: processData(data),
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.raw.label || "";
            const xValue = context.raw.x;
            const yValue = context.raw.y;
            const rValue = context.raw.r;
            return `${label}: (x: ${xValue}, y: ${yValue}, r: ${rValue})`;
          },
        },
      },
      datalabels: {
        display: false,
      },
      legend: {
        position: 'top',
      },
    },
    elements: {
      point: {
        radius: function (context) {
          return context.raw.r;
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "IMPLEMENTATION ORDER",
        },
        ticks: {
          beginAtZero: true,
        },
      },
      y: {
        title: {
          display: true,
          text: "CRITICALITY",
        },
        ticks: {
          beginAtZero: true,
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="bubbleChart" style={{ height: '500px', padding: '20px' }}>
      <Bubble data={chartData} options={options} />
    </div>
  );
};

export default BubbleChart;