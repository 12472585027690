import React from "react";

const UserContext = React.createContext({
  username: "",
  setUsername: () => {},
  user: "",
  setUser: () => {},
  userid: 0,
  setUserId: () => {},
  clientid: 0,
  setClientId: () => {},
  assessmentid: 0,
  setAssessmentId: () => {},
  signed: false,
  setSigned: () => {},
  enableaccesscode: "",
  setEnableaccesscode: () => {},
  token: "",
  setToken: () => {},
});

export default UserContext;
