import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import fetchUtility from "../../utilities/fetchUtilities";
import UserContext from "../../utilities/UserContext";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "./NavBar.css";

export const NavBar = ({ setPageTitle }) => {
  const [responseAssessment, setResponseAssessment] = useState([]);
  const userContext = React.useContext(UserContext);
  const navigate = useNavigate();
  const enableaccesscode = userContext.enableaccesscode.enableaccesscode;

  const fetchData = async () => {
    try {
      const transactionType = "getAssessmentsbyUser";
      const bodyData = {
        token: userContext.token.token,
        transactiontype: transactionType,
        clientid: userContext.clientid.clientid,
        userid: userContext.userid.userid,
        username: userContext.username.username,
      };
      const responseQuestions = await fetchUtility(bodyData, "assessment");
      setResponseAssessment(responseQuestions);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };

  useEffect(() => {
    if (userContext.clientid && userContext.clientid.clientid) {
      fetchData();
    }
  }, [userContext]);

  const handleHomeClick = () => {
    setPageTitle("Home");
  };

  const handleReportsClick = () => {
    setPageTitle("Reports");
  };

  const handleOnClick = (assessmentId, assessment) => {
    setPageTitle(assessment);
    userContext.setAssessmentId({ assessmentid: assessmentId });
    navigate("/Assessment");
  };

  return (
    <>
      {userContext.signed.signed === true && (
        <Navbar bg="dark" expand="lg" variant="dark">
          <div className="container-fluid">
            <Navbar.Brand as={Link} to="/Home">
              <img src="./logo.png" width="40" alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarNavDropdown" />
            <Navbar.Collapse id="navbarNavDropdown">
              <Nav className="me-auto mb-2 mb-lg-0">
                {enableaccesscode.substring(0, 1) === "1" && (
                  <Nav.Link as={Link} to="/Home" onClick={handleHomeClick}>
                    Home
                  </Nav.Link>
                )}
                {enableaccesscode.substring(1, 2) === "1" && (
                  <Nav.Link
                    as={Link}
                    to="/Reports"
                    onClick={handleReportsClick}
                  >
                    Reports
                  </Nav.Link>
                )}
                {enableaccesscode.substring(2, 3) === "1" && (
                  <NavDropdown title="Assessments" id="navbarScrollingDropdown">
                    {responseAssessment.map(
                      (lsAssessment, lsAssessmentindex) => (
                        <NavDropdown.Item
                          key={lsAssessmentindex}
                          onClick={() =>
                            handleOnClick(
                              lsAssessment.assessmentid,
                              lsAssessment.assessment
                            )
                          }
                        >
                          {lsAssessment.assessment}
                        </NavDropdown.Item>
                      )
                    )}
                  </NavDropdown>
                )}
                {enableaccesscode.substring(3, 4) === "1" && (
                  <Nav.Link as={Link} to="/Setup" onClick={handleReportsClick}>
                    Setup
                  </Nav.Link>
                )}
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      )}
    </>
  );
};

export default NavBar;
