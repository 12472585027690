// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  background-color:#282c34; /* Cambia este color al que desees */
  padding: 20px; /* Añade un espacio alrededor del contenido del footer */
  text-align: center;
  color: #ffffff; 
}`, "",{"version":3,"sources":["webpack://./src/components/layout/Footer.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB,EAAE,oCAAoC;EAC9D,aAAa,EAAE,wDAAwD;EACvE,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":["footer {\n  background-color:#282c34; /* Cambia este color al que desees */\n  padding: 20px; /* Añade un espacio alrededor del contenido del footer */\n  text-align: center;\n  color: #ffffff; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
