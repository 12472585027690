import React, { Component } from "react";
import styles from "./FormAssessment.module.css";
import fetchUtility from "../../utilities/fetchUtilities";

class FormAnswers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      responseSonQuestions: [],
    };

    if (this.props.questiontype === "Dependent questions") {
      this.fetchGetSonQuestionsData();
    }
  }

  async fetchGetSonQuestionsData() {
    let bodyData;

    const { questionid, userid, username, token } = this.props;

    try {
      let transactionType = "getSonQuestions";

      bodyData = {
        transactiontype: transactionType,
        questionid: questionid,
        userid: userid,
        username: username,
        token: token,
      };

      //console.log("fetchGetSonQuestionsData: " + bodyData);

      const responseSonQuestions = await fetchUtility(bodyData, "assessment");

      this.setState({
        responseSonQuestions,
      });
    } catch (error) {
      //console.error("Error al obtener los datos:", error);
      //console.error(bodyData);
    }
  }

  sendFormDataToServer = async (
    answertype,
    questionid,
    answerid,
    answer,
    checked,
    controltype
  ) => {
    let bodySubmitData = {};
    bodySubmitData = {
      transactiontype: "SaveAnwers",
      answertype: answertype,
      assessmentid: this.props.assessmentId,
      questionid: questionid,
      answerid: answerid,
      answer: answer,
      checked: checked,
      controltype: controltype,
      userid: this.props.userid,
      username: this.props.username,
      token: this.props.token,
    };

    try {
      //console.log("submit: " + JSON.stringify(bodySubmitData));
      const responseSubmit = await fetchUtility(bodySubmitData, "assessment");
      return responseSubmit;
    } catch (error) {
      //console.log(error.message);
    }
  };

  deleteAnswer = async (questionid) => {
    let bodySubmitData = {};
    bodySubmitData = {
      transactiontype: "deleteAnswer",
      assessmentid: this.props.assessmentId,
      questionid: questionid,
      userid: this.props.userid,
      username: this.props.username,
      token: this.props.token,
    };
    try {
      const responseSubmit = await fetchUtility(bodySubmitData, "assessment");
      return responseSubmit;
    } catch (error) {
      console.log(error.message);
    }
  };

  handleOnBlur = (e) => {
    if (e.target.value !== "") {
      this.sendFormDataToServer(
        e.target.dataset.info,
        e.target.name,
        e.target.id,
        e.target.value,
        e.target.checked,
        e.target.type
      );
    }
  };

  handleOnBlurAux = (e) => {
    const suffix = "text";
    let id;
    if (e.target.id.endsWith(suffix)) {
      id = e.target.id.substring(0, e.target.id.length - suffix.length);
    }
    if (e.target.value !== "") {
      this.sendFormDataToServer(
        e.target.dataset.info,
        e.target.name,
        id,
        e.target.value,
        e.target.checked,
        e.target.type
      );
    }
  };

  handleOnChange = (e) => {
    this.sendFormDataToServer(
      e.target.dataset.info,
      e.target.name,
      e.target.id,
      e.target.value,
      e.target.checked,
      e.target.type
    );
    const { name, value, type } = e.target;

    if (
      this.props.answertype === "Multiple Options - Open answer" ||
      this.props.answertype === "Multiple Answers - Open answer"
    ) {
      const fmElement = document.querySelector(
        `input[id="${e.target.id + "text"}"], textarea[id="${
          e.target.id + "text"
        }"]`
      );
      if (fmElement) {
        fmElement.disabled = false;
      }
    }

    if (type === "radio") {
      // Obtener todos los radio buttons del mismo grupo
      const radioButtons = document.querySelectorAll(
        `input[type="radio"][name="${name}"]`
      );

      radioButtons.forEach((radio) => {
        if (radio !== e.target && !radio.checked) {
          const fmElement = document.querySelector(
            `input[id="${radio.id + "text"}"], textarea[id="${
              radio.id + "text"
            }"]`
          );
          if (fmElement) {
            fmElement.value = "";
            fmElement.disabled = true;
          }
        }
      });
      if (this.props.questiontype === "Dependent questions") {
        let fmElementQuestion;
        this.state.responseSonQuestions.forEach((lsSonQuestion) => {
          fmElementQuestion = document.querySelector(
            `label[id="lbl${lsSonQuestion.questionid}"]`
          );
          if (fmElementQuestion && e.target.value === "Yes") {
            const divElement = document.getElementById(
              "div" + lsSonQuestion.questionid
            );
            if (divElement) {
              // Hacer visible el div
              divElement.style.display = "block"; // o "inline-block" si quieres que aparezca en línea
              divElement.style.visibility = "visible";
            }
          } else if (fmElementQuestion && e.target.value === "No") {
            const divElement = document.getElementById(
              "div" + lsSonQuestion.questionid
            );
            if (divElement) {
              // Hacer no visible el div
              divElement.style.display = "none";
              this.deleteAnswer(lsSonQuestion.questionid);
            }
          }
        });
      }
    } else if (type === "checkbox") {
      // Obtener todos los checkbox del mismo grupo
      const checkboxButtons = document.querySelectorAll(
        `input[type="checkbox"][name="${name}"]`
      );

      checkboxButtons.forEach((checkbox) => {
        if (!checkbox.checked) {
          const fmElement = document.querySelector(
            `input[id="${checkbox.id + "text"}"], textarea[id="${
              checkbox.id + "text"
            }"]`
          );
          if (fmElement) {
            fmElement.value = "";
            fmElement.disabled = true;
          }
        }
      });
    }

    // Actualiza el estado utilizando la función de actualización pasada como prop
    this.props.updateState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  render() {
    const { answertype, answerid, questionid, answer } = this.props;

    return (
      <>
        {answertype === "Multiple Options" ? (
          <div className={styles["inline-container"]}>
            <input
              className={styles.FormAssessmentInput}
              id={answerid}
              name={questionid}
              type="radio"
              value={answer}
              onChange={this.handleOnChange}
            />
            <span className={styles.FormAssessmentLabel}>{answer}</span>
          </div>
        ) : answertype === "Multiple Options - Open answer" ? (
          <>
            <div className={styles["inline-container"]}>
              <input
                className={styles.FormAssessmentInput}
                id={answerid}
                name={questionid}
                type="radio"
                value={answer}
                data-info={answertype}
                onChange={this.handleOnChange}
              />
              <span className={styles.FormAssessmentLabel}>{answer}</span>
            </div>
            <textarea
              className={styles.FormAssessmentInput}
              id={answerid + "text"}
              name={questionid}
              type="text"
              data-info={answertype}
              placeholder="Enter the information here"
              disabled
              onBlur={this.handleOnBlurAux}
            />
          </>
        ) : answertype === "Multiple Answers" ||
          answertype === "Multiple Answers - Open answer" ? (
          <>
            <div className={styles["inline-container"]}>
              <input
                className={styles.FormAssessmentInput}
                id={answerid}
                name={questionid}
                type="checkbox"
                value={answer}
                data-info={answertype}
                onChange={this.handleOnChange}
              />
              <span className={styles.FormAssessmentLabel}>{answer}</span>
            </div>
            {answertype === "Multiple Answers - Open answer" && (
              <textarea
                className={styles.FormAssessmentInput}
                id={answerid + "text"}
                name={questionid}
                type="text"
                data-info={answertype}
                placeholder="Enter the information here"
                disabled
                onBlur={this.handleOnBlurAux}
              />
            )}
          </>
        ) : answertype === "Rating 1-5" ? (
          <div className={styles["inline-container"]}>
            <input
              className={styles.FormAssessmentInput}
              id={answerid}
              name={questionid}
              type="range"
              min="0"
              max="5"
              data-info={answertype}
              onChange={this.handleOnChange}
            />
          </div>
        ) : answertype === "Open text" ? (
          <div className={styles["inline-container"]}>
            <input
              className={styles.FormAssessmentInput}
              id={answerid}
              name={questionid}
              type="text"
              data-info={answertype}
              onBlur={this.handleOnBlur}
            />
          </div>
        ) : answertype === "Open large text" ? (
          <div className={styles["inline-container"]}>
            <textarea
              className={styles.FormAssessmentInput}
              id={answerid}
              name={questionid}
              type="text"
              rows="5"
              data-info={answertype}
              onBlur={this.handleOnBlur}
            />
          </div>
        ) : (
          <span className={styles.FormAssessmentLabel}>{answer}</span>
        )}
      </>
    );
  }
}

export default FormAnswers;
