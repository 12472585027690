import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

const PDFDynamicTable = ({ data }) => {
    if (!data || data.length === 0) {
        return <Text>No hay datos para mostrar</Text>;
    }

    const headers = Object.keys(data[0]);
    const columnWidth = `${100 / headers.length}%`;

    const styles = StyleSheet.create({
        tableContainer: {
            marginVertical: 10,
            marginHorizontal: 0,
        },
        table: {
            display: 'table',
            width: '100%',
            borderStyle: 'solid',
            borderColor: '#000000',
            borderWidth: 1,
        },
        tableRow: {
            flexDirection: 'row',
            borderBottomColor: '#000000',
            borderBottomWidth: 1,
        },
        tableRowHeader: {
            flexDirection: 'row',
            backgroundColor: '#f8d7da',
            borderBottomColor: '#000000',
            borderBottomWidth: 1,
        },
        tableRowLast: {
            flexDirection: 'row',
        },
        tableCol: {
            width: columnWidth,
            borderRightColor: '#000000',
            borderRightWidth: 1,
            paddingVertical: 5,
            paddingHorizontal: 8,
        },
        tableColLast: {
            width: columnWidth,
            paddingVertical: 5,
            paddingHorizontal: 8,
        },
        tableCellHeader: {
            fontSize: 10,
            fontWeight: 'bold',
            textAlign: 'center',
        },
        tableCell: {
            fontSize: 10,
            textAlign: 'left',
        },
        tableCellNumeric: {
            fontSize: 10,
            textAlign: 'center',
        },
        // Estilo específico para la última columna
        tableCellLastColumn: {
            fontSize: 10,
            textAlign: 'center',
        }
    });

    const formatColumnName = (header) => {
        return header
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const isNumeric = (value) => {
        return !isNaN(value) && value !== null && value !== '';
    };

    const formatValue = (value) => {
        if (value === null || value === undefined) return '';
        if (typeof value === 'number') {
            if (value >= 0 && value <= 100 && String(value).includes('.')) {
                return `${value.toFixed(2)}%`;
            }
            return value.toString();
        }
        return value;
    };

    // Función para determinar el estilo de la celda
    const getCellStyle = (value, colIndex, isLastColumn) => {
        if (isLastColumn) {
            return styles.tableCellLastColumn;
        }
        return isNumeric(value) ? styles.tableCellNumeric : styles.tableCell;
    };

    return (
        <View style={styles.tableContainer}>
            <View style={styles.table}>
                {/* Header */}
                <View style={styles.tableRowHeader}>
                    {headers.map((header, index) => (
                        <View 
                            key={index} 
                            style={index === headers.length - 1 ? styles.tableColLast : styles.tableCol}
                        >
                            <Text style={styles.tableCellHeader}>
                                {formatColumnName(header)}
                            </Text>
                        </View>
                    ))}
                </View>

                {/* Rows */}
                {data.map((row, rowIndex) => (
                    <View 
                        key={rowIndex} 
                        style={rowIndex === data.length - 1 ? styles.tableRowLast : styles.tableRow}
                    >
                        {headers.map((header, colIndex) => {
                            const value = row[header];
                            const isLastColumn = colIndex === headers.length - 1;
                            
                            return (
                                <View 
                                    key={`${rowIndex}-${colIndex}`} 
                                    style={isLastColumn ? styles.tableColLast : styles.tableCol}
                                >
                                    <Text style={getCellStyle(value, colIndex, isLastColumn)}>
                                        {formatValue(value)}
                                    </Text>
                                </View>
                            );
                        })}
                    </View>
                ))}
            </View>
        </View>
    );
};

export default PDFDynamicTable;