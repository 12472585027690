import React, { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { View, Image } from "@react-pdf/renderer";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const BrowserRadarChart = ({ data, onRender }) => {
  const chartRef = useRef(null);

  const chartData = {
    labels: data.map((item) => item["Section"]),
    datasets: [
      {
        label: "Compliance Percentage",
        data: data.map(
          (item) => (item["Achieved Points"] / item["Section Points"]) * 100
        ),
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    scales: {
      r: {
        angleLines: {
          display: true,
        },
        suggestedMin: 0,
        suggestedMax: 100,
        pointLabels: {
          font: {
            size: 12,
          },
          padding: 25,
        },
        ticks: {
          stepSize: 20,
          callback: function (value) {
            return value + "%";
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          padding: 20,
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let value = context.raw;
            return `${value.toFixed(2)}%`;
          },
        },
      },
      datalabels: {
        display: true,
        formatter: function (value) {
          return value.toFixed(2) + "%";
        },
        color: "#666",
        font: {
          size: 10,
        },
        anchor: "end",
        align: "start",
        offset: 5,
      },
    },
  };

  useEffect(() => {
    // Usar setTimeout para asegurar que el canvas está listo
    const timer = setTimeout(() => {
      if (chartRef.current) {
        const canvas = chartRef.current.canvas;
        try {
          const imageData = canvas.toDataURL("image/png");
          onRender(imageData);
        } catch (error) {
          console.error("Error al generar la imagen:", error);
        }
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [data, onRender]);

  return (
    <div
      style={{
        width: "500px",
        height: "400px",
        visibility: "hidden",
        position: "fixed",
        top: 0,
        left: 0,
      }}
    >
      <Radar ref={chartRef} data={chartData} options={options} />
    </div>
  );
};

const PDFRadarChart = ({ data }) => {
  const [chartImage, setChartImage] = useState(null);
  const containerRef = useRef(null);
  const rootRef = useRef(null);

  useEffect(() => {
    // Crear contenedor
    const container = document.createElement("div");
    document.body.appendChild(container);
    containerRef.current = container;

    // Crear root
    const root = createRoot(container);
    rootRef.current = root;

    // Renderizar el componente
    root.render(
      <BrowserRadarChart
        data={data}
        onRender={(imageData) => {
          setChartImage(imageData);
        }}
      />
    );

    // Cleanup
    return () => {
      if (rootRef.current) {
        rootRef.current.unmount();
      }
      if (containerRef.current && containerRef.current.parentNode) {
        containerRef.current.parentNode.removeChild(containerRef.current);
      }
    };
  }, [data]);

  if (!chartImage) {
    return null;
  }

  return (
    <View style={{ width: "100%", height: 400, padding: 20 }}>
      <Image
        src={chartImage}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
      />
    </View>
  );
};

export default PDFRadarChart;
