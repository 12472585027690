import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import NavBar from "./components/navegation/NavBar";
import Home from "./components/pages/Home";
import Assessment from "./components/pages/Assessment";
import Reports from "./components/pages/Reports";
import Setup from "./components/pages/Setup";
import Login from "./components/navegation/Login";

import UserContext from "./utilities/UserContext";

import "./App.css";

function App() {
  const [pageTitle, setPageTitle] = useState("MSSP Latam Portal");
  const [username, setUsername] = useState({ username: "" });
  const [user, setUser] = useState({ user: "" });
  const [userid, setUserId] = useState({ userid: "" });
  const [clientid, setClientId] = useState({ clientid: 0 });
  const [assessmentid, setAssessmentId] = useState({ assessmentid: 0 });
  const [signed, setSigned] = useState({ signed: false });
  const [enableaccesscode, setEnableaccesscode] = useState({
    enableaccesscode: "",
  });
  const [token, setToken] = useState({ token: "" });

  return (
    <UserContext.Provider
      value={{
        username,
        setUsername,
        user,
        setUser,
        userid,
        setUserId,
        clientid,
        setClientId,
        assessmentid,
        setAssessmentId,
        signed,
        setSigned,
        enableaccesscode,
        setEnableaccesscode,
        token,
        setToken,
      }}
    >
      <div className="App">
        <div>
          <Header title={pageTitle} />
        </div>
        <Router>
          <NavBar setPageTitle={setPageTitle} />
          <Routes>
            <Route path="/" exact element={<Login />} />{" "}
            <Route path="/Home" exact element={<Home />} />
            {(Header.title = "Home")}
            <Route path="/Setup" exact element={<Setup />} />{" "}
            <Route path="/Assessment" exact element={<Assessment />} />{" "}
            <Route path="/Reports" exact element={<Reports />} />{" "}
            <Route path="/Login" exact element={<Login />} />{" "}
          </Routes>
        </Router>
        <Footer />
      </div>
    </UserContext.Provider>
  );
}

export default App;
