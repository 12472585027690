import React, { useState, useEffect } from "react";
import { View, Text, Image, StyleSheet } from "@react-pdf/renderer";
import fetchUtility from "../../utilities/fetchUtilities";
import getRiskIcon from "../../utilities/manageIcons";

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
    marginTop: 20,
  },
  table: {
    width: "100%",
    borderWidth: 1,
    borderColor: "#dee2e6",
    marginTop: 10,
    marginBottom: 20,
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#f8d7da",
    borderBottomWidth: 1,
    borderBottomColor: "#dee2e6",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#dee2e6",
    minHeight: 40,
  },
  columnLeft: {
    width: "70%",
    padding: 8,
    justifyContent: "center",
    borderRightWidth: 1,
    borderRightColor: "#dee2e6",
  },
  columnRight: {
    width: "30%",
    padding: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  cellHeader: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
  },
  cellText: {
    fontSize: 11,
    textAlign: "left",
  },
  riskContainer: {
    alignItems: "center",
  },
  riskIcon: {
    width: 20,
    height: 20,
    marginBottom: 4,
  },
  riskText: {
    fontSize: 11,
    textAlign: "center",
  }
});

const PDFDiagnosisReport = ({ assessmentid, userid, username, token }) => {
  const [generalDiagnosis, setGeneralDiagnosis] = useState([]);
  const [generalDiagnosisBySection, setGeneralDiagnosisBySection] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch general diagnosis
        const genDiagnosisData = await fetchUtility(
          {
            transactiontype: "getGenelDignosis",
            assessmentid,
            userid,
            username,
            token,
          },
          "reports"
        );
        setGeneralDiagnosis(genDiagnosisData);

        // Fetch diagnosis by section
        const sectionDiagnosisData = await fetchUtility(
          {
            transactiontype: "getGenelDignosisbySection",
            assessmentid,
            userid,
            username,
            token,
          },
          "reports"
        );
        setGeneralDiagnosisBySection(sectionDiagnosisData);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [assessmentid, userid, username, token]);

  const getRiskLevel = (balance) => {
    if (balance >= 0 && balance < 28) return "Bajo";
    if (balance >= 28 && balance < 56) return "Medio";
    if (balance >= 56 && balance < 84) return "Alto";
    if (balance >= 84 && balance <= 100) return "Crítico";
    return "Desconocido";
  };

  return (
    <View style={styles.container}>   
      {/* Primera tabla: Riesgo General */}
      <Text style={styles.sectionTitle}>Nivel de riesgo general del assessment</Text>
      <View style={styles.table}>
        <View style={styles.tableHeader}>
          <View style={styles.columnLeft}>
            <Text style={styles.cellHeader}>Assessment Type</Text>
          </View>
          <View style={styles.columnRight}>
            <Text style={styles.cellHeader}>Risk Level</Text>
          </View>
        </View>

        {generalDiagnosis.map((item, index) => (
          <View key={index} style={styles.tableRow}>
            <View style={styles.columnLeft}>
              <Text style={styles.cellText}>{item.assessmentype}</Text>
            </View>
            <View style={styles.columnRight}>
              <View style={styles.riskContainer}>
                <Image 
                  style={styles.riskIcon} 
                  src={getRiskIcon(getRiskLevel(item.balance))}
                />
                <Text style={styles.riskText}>{getRiskLevel(item.balance)}</Text>
              </View>
            </View>
          </View>
        ))}
      </View>

      {/* Segunda tabla: Riesgo por Sección */}
      <Text style={styles.sectionTitle}>Nivel de riesgo del assessment por sección</Text>
      <View style={styles.table}>
        <View style={styles.tableHeader}>
          <View style={styles.columnLeft}>
            <Text style={styles.cellHeader}>Section</Text>
          </View>
          <View style={styles.columnRight}>
            <Text style={styles.cellHeader}>Risk Level</Text>
          </View>
        </View>

        {generalDiagnosisBySection.map((item, index) => (
          <View key={index} style={styles.tableRow}>
            <View style={styles.columnLeft}>
              <Text style={styles.cellText}>{item.section}</Text>
            </View>
            <View style={styles.columnRight}>
              <View style={styles.riskContainer}>
                <Image 
                  style={styles.riskIcon} 
                  src={getRiskIcon(getRiskLevel(item.balance))}
                />
                <Text style={styles.riskText}>{getRiskLevel(item.balance)}</Text>
              </View>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

export default PDFDiagnosisReport;