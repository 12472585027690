import React from "react";
import "./Footer.css"

const Footer = () => {
  return (
    <footer>
      &copy; Fortinet, Inc. All Rights Reserved. {new Date().getFullYear()} 
    </footer>
  );
};

export default Footer;
