import React from 'react';
import { useNavigate } from 'react-router-dom';

function NavigationHelper({ to }) {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate(to);
  }, [navigate, to]);

  return null;
}

export default NavigationHelper;
