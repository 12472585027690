import React from 'react';
import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Registrar componentes necesarios
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ChartDataLabels
);

const RadarChart = ({ data }) => {
  // Extraer datos del JSON
  const labels = data.map(item => item['Section']);
  const sectionPercentage = data.map(
    item => (item['Achieved Points'] / item['Section Points']) * 100
  );

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Compliance Percentage",
        data: sectionPercentage,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        datalabels: {
          display: false,
        },
        pointRadius: 0,
      },
    ],
  };

  const options = {
    layout: {
      padding: {
        top: 10,
        bottom: 10,
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      r: {
        angleLines: {
          display: true,
        },
        pointLabels: {
          display: true,
          font: {
            size: 16,
          },
        },
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          display: true,
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="chart dataCard radarChart" style={{ height: '400px' }}>
      <Radar data={chartData} options={options} />
    </div>
  );
};

export default RadarChart;