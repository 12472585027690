import React from "react";
import PropTypes from "prop-types";

import UserContext from "../../utilities/UserContext";

const Header = (props) => {


  const userContext = React.useContext(UserContext);
  return (
    <header className="App-header">
      <h1 className="App-title">{props.title}</h1>
      {userContext.signed.signed === true &&(
      <a>{"Welcome " + userContext.user.user.replace(/"/g, '')}</a>
      )}
    </header>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
};
export default Header;
