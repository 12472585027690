import React, { useState, useEffect, useContext, useRef } from "react";
import UserContext from "../../utilities/UserContext";
import fetchUtility from "../../utilities/fetchUtilities";
import NavigationHelper from "../../utilities/NavigationHelper";
import FormAnswers from "./FormAnswers";
import RingLoader from "react-spinners/RingLoader";
import ModalMessage from "../navegation/ModalMessage";
import styles from "./FormAssessment.module.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";

// Registrar los componentes necesarios de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  BarElement
);

const FormAssessment = () => {
  const context = useContext(UserContext);
  const scrollContainerRef = useRef(null);

  const [responseQuestions, setResponseQuestions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [responseAnswer, setResponseAnswer] = useState([]);
  const [responseHeader, setResponseHeader] = useState([]);
  const [responseSonQuestions, setResponseSonQuestions] = useState([]);
  const [responseSonAnswers, setResponseSonAnswers] = useState([]);
  const [containerHeight, setContainerHeight] = useState("100vh");
  const [loading, setLoading] = useState(true);

  const [modalMessage, setModalMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const {
        assessmentid: { assessmentid },
        userid: { userid },
        username: { username },
        token: { token },
      } = context;
      const transactionType = "getQuestionsbyAssessmentId";

      const bodyData = {
        transactiontype: transactionType,
        assessmentid: assessmentid,
        userid: userid,
        username: username,
        token: token,
      };

      try {
        const responseQuestions = await fetchUtility(bodyData, "assessment");
        setResponseQuestions(responseQuestions);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };

    fetchData();
  }, [context]);

  useEffect(() => {
    const updateContainerHeight = () => {
      if (scrollContainerRef.current) {
        const windowHeight = window.innerHeight;
        const offset = scrollContainerRef.current.offsetTop;
        setContainerHeight(`${windowHeight - offset}px`);
      }
    };

    updateContainerHeight();
    window.addEventListener("resize", updateContainerHeight);

    return () => window.removeEventListener("resize", updateContainerHeight);
  }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const {
          assessmentid: { assessmentid },
          userid: { userid },
          username: { username },
          token: { token },
        } = context;

        // Fetch answers data
        const fetchAnswersData = async () => {
          const responseAnswerPromises = responseQuestions.map(
            async (lsQuestions) => {
              if (lsQuestions.questiontype !== "Multidimencional - Son") {
                const bodyDataAnswers = {
                  transactiontype: "getAnswerbyQuestionId",
                  assessmentid: assessmentid,
                  questionid: lsQuestions.questionid,
                  userid: userid,
                  username: username,
                  token: token,
                };
                return await fetchUtility(bodyDataAnswers, "assessment");
              }
            }
          );
          return await Promise.all(responseAnswerPromises);
        };

        // Fetch header data
        const fetchHeaderData = async () => {
          const responseHeaderPromises = responseQuestions.map(
            async (lsQuestions) => {
              if (
                lsQuestions.parentquestion > 0 &&
                lsQuestions.questiontype === "Multidimencional"
              ) {
                const bodyDataHeader = {
                  transactiontype: "getSonAnswers",
                  questionid: lsQuestions.questionid,
                  userid: userid,
                  username: username,
                  token: token,
                };
                return await fetchUtility(bodyDataHeader, "assessment");
              } else {
                return [];
              }
            }
          );
          return await Promise.all(responseHeaderPromises);
        };

        // Fetch son questions data
        const fetchSonQuestionsData = async () => {
          const responseSonQuestionsPromises = responseQuestions.map(
            async (lsQuestions) => {
              if (
                lsQuestions.parentquestion > 0 &&
                lsQuestions.questiontype === "Multidimencional"
              ) {
                const bodyDataQuestions = {
                  transactiontype: "getSonQuestions",
                  questionid: lsQuestions.questionid,
                  userid: userid,
                  username: username,
                  token: token,
                };
                return await fetchUtility(bodyDataQuestions, "assessment");
              } else {
                return [];
              }
            }
          );
          return await Promise.all(responseSonQuestionsPromises);
        };

        // Fetch son answers data
        const fetchSonAnswersData = async () => {
          const responseSonAnswersPromises = responseQuestions.map(
            async (lsQuestions) => {
              if (
                lsQuestions.parentquestion > 0 &&
                lsQuestions.questiontype === "Multidimencional"
              ) {
                const bodyDataAnswers = {
                  transactiontype: "getSonAnswerswithAnswerid",
                  questionid: lsQuestions.questionid,
                  userid: userid,
                  username: username,
                  token: token,
                };
                return await fetchUtility(bodyDataAnswers, "assessment");
              } else {
                return [];
              }
            }
          );
          return await Promise.all(responseSonAnswersPromises);
        };

        const [answersData, headerData, sonQuestionsData, sonAnswersData] =
          await Promise.all([
            fetchAnswersData(),
            fetchHeaderData(),
            fetchSonQuestionsData(),
            fetchSonAnswersData(),
          ]);

        setResponseAnswer(answersData);
        setResponseHeader(headerData);
        setResponseSonQuestions(sonQuestionsData);
        setResponseSonAnswers(sonAnswersData);
        await fillOutControls();
        setLoading(false); // Cambiar el estado de loading a false una vez que se hayan cargado todos los datos
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };

    if (responseQuestions.length > 0) {
      fetchAllData();
    }
  }, [context, responseQuestions]);

  const getSonQuestionbyID = async (questionid) => {
    const {
      userid: { userid },
      username: { username },
      token: { token },
    } = context;

    const bodySonQuestionsData = {
      transactiontype: "getSonQuestions",
      questionid: questionid,
      userid: userid,
      username: username,
      token: token,
    };

    try {
      const response = await fetchUtility(bodySonQuestionsData, "assessment");
      return response;
    } catch (error) {
      console.error("Error fetching son questions data:", error);
      return [];
    }
  };

  const fillOutControls = async () => {
    try {
      const {
        assessmentid: { assessmentid },
        userid: { userid },
        username: { username },
        token: { token },
      } = context;

      const bodyData = {
        transactiontype: "getAssessmentAnswer",
        assessmentid: assessmentid,
        userid: userid,
        username: username,
        token: token,
      };
      const rspAnswers = await fetchUtility(bodyData, "assessment");

      rspAnswers.map(async (lsAnswers) => {
        const fmElement = document.querySelector(
          `input[id="${lsAnswers.answerid}"], textarea[id="${lsAnswers.answerid}"]`
        );
        if (fmElement) {
          switch (lsAnswers.answertype) {
            case "Multiple Options":
            case "Rating 1-5":
            case "Multiple Answers":
            case "Open text":
            case "Open large text":
              fmElement.value = lsAnswers.answer;
              if (fmElement.type === "radio" || fmElement.type === "checkbox") {
                fmElement.checked = true;

                if (
                  fmElement.type === "radio" &&
                  lsAnswers.questiontype === "Dependent questions" &&
                  lsAnswers.answer === "Yes"
                ) {
                  try {
                    let rspSonQuestions = await getSonQuestionbyID(
                      lsAnswers.questionid
                    );
                    rspSonQuestions.forEach((lsSonQuestions) => {
                      const divElement = document.getElementById(
                        "div" + lsSonQuestions.questionid
                      );
                      if (divElement) {
                        divElement.style.display = "block";
                        divElement.style.visibility = "visible";
                      }
                    });
                  } catch (error) {
                    console.error("Error al obtener los datos:", error);
                  }
                }
              }
              break;
            case "Multiple Options - Open answer":
            case "Multiple Answers - Open answer":
              fmElement.checked = true;
              const fmElementaux = document.querySelector(
                `input[id="${lsAnswers.answerid + "text"}"], textarea[id="${
                  lsAnswers.answerid + "text"
                }"]`
              );
              fmElementaux.value = lsAnswers.description;
              fmElementaux.disabled = false;
              break;
            default:
              console.log("Opción no definida: " + lsAnswers.answertype);
              break;
          }
        }
        return null;
      });
    } catch (error) {
      console.error("Error en la acción adicional:", error);
    }
  };

  const sendFormDataToServer = async (
    questionid,
    answerid,
    answer,
    checked,
    controltype
  ) => {
    const {
      assessmentid: { assessmentid },
      userid: { userid },
      username: { username },
      token: { token },
    } = context;

    const bodySubmitData = {
      transactiontype: "SaveAnwers",
      assessmentid: assessmentid,
      questionid: questionid,
      answerid: answerid,
      answer: answer,
      checked: checked,
      controltype: controltype,
      userid: userid,
      username: username,
      token: token,
    };

    try {
      const responseSubmit = await fetchUtility(bodySubmitData, "assessment");
      return responseSubmit;
    } catch (error) {
      console.log(error.message);
    }
  };

  const handlePrintControls = () => {
    const formControls = document.querySelectorAll("input");

    formControls.forEach((control) => {
      sendFormDataToServer(
        "SaveAnwers",
        control.name,
        control.id,
        control.value,
        control.checked,
        control.type
      );
    });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    if (e.target.type !== "text") {
      sendFormDataToServer(
        e.target.name,
        e.target.id,
        e.target.value,
        e.target.checked,
        e.target.type
      );
    }

    setSelectedAnswers((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  let section;

  const {
    assessmentid: { assessmentid },
    userid: { userid },
    username: { username },
    token: { token },
    signed: { signed },
  } = context;

  const getDisplayStyle = (questionType) => {
    return questionType === "Dependent questions - Son"
      ? { display: "none" }
      : { display: "block" };
  };

  if (loading) {
    return (
      <>
        <div className={styles.FormAssessmentBody}>LOADING...</div>
        <br />
        <br />
        <RingLoader
          className={styles.FormAssessmentLoading}
          color={"#000000"}
          loading={loading}
          size={150}
        />
        <br />
        <br />
      </>
    );
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Función para resetear los estilos de todos los labels y td
    const resetStyles = () => {
      document
        .querySelectorAll('[id^="lbl"], [id^="td"]')
        .forEach((element) => {
          element.style.color = "black"; // Resetea al color por defecto
        });
    };

    const fetchData = async () => {
      const {
        assessmentid: { assessmentid },
        userid: { userid },
        username: { username },
        token: { token },
      } = context;
      const transactionType = "getValidationAssessment";
      const bodyData = {
        transactiontype: transactionType,
        assessmentid: assessmentid,
        userid: userid,
        username: username,
        token: token,
      };

      try {
        const questionAnswered = await fetchUtility(bodyData, "assessment");
        return questionAnswered;
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        return null;
      }
    };

    const questionAnswered = await fetchData();

    if (questionAnswered && questionAnswered.length > 0) {
      resetStyles(); // Resetea los estilos antes de la nueva validación

      let unansweredQuestions = [];

      questionAnswered.forEach((question) => {
        if (!question.answered) {
          // Asumimos que cada pregunta tiene una propiedad 'answered'
          const label = document.getElementById(`lbl${question.questionid}`);
          const td = document.getElementById(`td${question.questionid}`);

          if (label) {
            label.style.color = "red";
            unansweredQuestions.push(question.questionid);
          }

          if (td) {
            td.style.color = "red";
            unansweredQuestions.push(question.questionid);
          }
        }
      });

      if (unansweredQuestions.length > 0) {
        setModalMessage(
          "The questions marked in red are unanswered. You need to answer them in order to continue."
        );
      } else {
        const {
          assessmentid: { assessmentid },
          userid: { userid },
          username: { username },
          token: { token },
        } = context;
        const transactionType = "setStatus2Reporting";
        const bodyData = {
          transactiontype: transactionType,
          assessmentid: assessmentid,
          userid: userid,
          username: username,
          token: token,
        };
        try {
          const Status2Reporting = await fetchUtility(bodyData, "assessment");
          setModalMessage("You have successfully completed your assessment.");
        } catch (error) {
          setModalMessage("Error while attempting to change the assessment status. Please contact the administrator.");
          console.error("Error al actulaizar el status del asessment", error);
        }
      }
    } else {
      setModalMessage(
        "There was an error validating your answers. Please try again."
      );
    }

    setShowModal(true);
  };

  return (
    <>
      <div
        ref={scrollContainerRef}
        className={styles.FormAssessmentBody}
        style={{
          height: containerHeight,
          overflowY: "auto",
          padding: "20px",
          boxSizing: "border-box",
        }}
      >
        {signed === false && <NavigationHelper to="/" />}
        <br />
        <br />
        <form className={styles.FormAssessment} onSubmit={handleSubmit}>
          <aside>
            <p>
              Welcome to the assessment. Please note that all your answers will
              be automatically saved without the need to press the finish
              button. This way, you can exit and return to the assessment
              without fear of losing the information you've provided. Press the
              'Finish' button only when you are sure of all your answers.
            </p>
          </aside>
          {responseQuestions.map((lsQuestions, lsQindex) => (
            <div className={styles.FormAssessmentDiv} key={lsQindex}>
              {lsQuestions.section !== section && (
                <>
                  <h2>
                    <label className={styles.FormAssessmentLabel}>
                      {(section = lsQuestions.section)}
                    </label>
                  </h2>

                  {lsQuestions.sectiondescription !== "" && (
                    <>
                      <h5>
                        <label className={styles.FormAssessmentLabel}>
                          {lsQuestions.sectiondescription}
                        </label>
                      </h5>
                      <br />
                    </>
                  )}
                </>
              )}

              {(lsQuestions.questiontype === "Standard" ||
                lsQuestions.questiontype === "Dependent questions" ||
                lsQuestions.questiontype === "Dependent questions - Son") &&
                responseAnswer[lsQindex] && (
                  <>
                    <div
                      id={"div" + lsQuestions.questionid}
                      style={getDisplayStyle(lsQuestions.questiontype)}
                    >
                      <br />
                      <label
                        className={styles.FormAssessmentLabel}
                        id={"lbl" + lsQuestions.questionid}
                      >
                        {lsQuestions.questionorder +
                          " .- " +
                          lsQuestions.question}
                      </label>
                      <label className={styles.FormAssessmentLabel}>
                        {lsQuestions.description}
                      </label>
                      <>
                        {responseAnswer[lsQindex].map((lsAnswer, index) => (
                          <FormAnswers
                            className={styles.FormAssessmentDiv}
                            key={index}
                            assessmentId={assessmentid}
                            questiontype={lsQuestions.questiontype}
                            questionid={lsQuestions.questionid}
                            answertype={lsAnswer.answertype}
                            answerid={lsAnswer.answerid}
                            answer={lsAnswer.answer}
                            display={getDisplayStyle(lsQuestions.questiontype)}
                            userid={userid}
                            username={username}
                            token={token}
                            updateState={(updatedState) =>
                              setResponseAnswer((prevState) => ({
                                ...prevState,
                                ...updatedState,
                              }))
                            }
                          />
                        ))}
                      </>
                    </div>
                  </>
                )}

              {lsQuestions.parentquestion > 0 &&
                lsQuestions.questiontype === "Multidimencional" && (
                  <>
                    <label className={styles.FormAssessmentLabel}>
                      {lsQuestions.questionorder +
                        " .- " +
                        lsQuestions.question}
                    </label>
                    <label className={styles.FormAssessmentLabel}>
                      {lsQuestions.description}
                    </label>
                    <table className={styles.FormAssessmentTable}>
                      <thead>
                        <tr>
                          <th className={styles.FormAssessmentTh}>Question</th>
                          {responseHeader[lsQindex] && (
                            <>
                              {responseHeader[lsQindex].map(
                                (lsHeader, index) => (
                                  <th
                                    className={styles.FormAssessmentTh}
                                    key={index}
                                  >
                                    {lsHeader.answer}
                                  </th>
                                )
                              )}
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {responseSonQuestions[lsQindex] &&
                          responseSonAnswers[lsQindex] &&
                          responseSonQuestions[lsQindex].map(
                            (lsTQuestions, index) => (
                              <tr key={index}>
                                <td
                                  className={styles.FormAssessmentTd}
                                  id={"td" + lsTQuestions.questionid}
                                >
                                  {lsTQuestions.question}
                                </td>
                                {responseSonAnswers[lsQindex].map(
                                  (lsTAnswers, subIndex) => (
                                    <React.Fragment key={subIndex}>
                                      {lsTAnswers.question ===
                                        lsTQuestions.question && (
                                        <td
                                          className={styles.FormAssessmentTd}
                                          key={lsTAnswers.answerid}
                                        >
                                          <input
                                            type="radio"
                                            id={lsTAnswers.answerid}
                                            name={lsTAnswers.questionid}
                                            value={lsTAnswers.answer}
                                            onChange={handleOnChange}
                                          />
                                        </td>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </>
                )}
            </div>
          ))}
          <input type="submit" value="Finish" />
          <aside>
            <p>
              Please note that once you press the 'Finish' button, you will no
              longer have access to the questionnaire.
            </p>
          </aside>
        </form>
      </div>
      <ModalMessage
        show={showModal}
        onHide={handleCloseModal}
        message={modalMessage}
      />
    </>
  );
};

export default FormAssessment;
