const getRiskIcon = (riskLevel) => {
    switch (riskLevel) {
      case 'Bajo':
        return '/icons/risk-low.png';
      case 'Medio':
        return '/icons/risk-medium.png';
      case 'Alto':
        return '/icons/risk-high.png';
      case 'Crítico':
        return '/icons/risk-critical.png';
      default:
        return '/icons/default.png'; // Icono por defecto si no coincide
    }
  };
  
export default getRiskIcon;
