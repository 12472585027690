import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ModalMessage({ show, onHide, message }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Operation Result
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>
          {message}
        </pre>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalMessage;
