import React, { useState, useEffect, useContext, useCallback } from "react";
import UserContext from "../../utilities/UserContext";
import fetchUtility from "../../utilities/fetchUtilities";
import TreeView from "./TreeView";
import ModalMessage from "../navegation/ModalMessage";
import styles from "./ManageOrganizations.module.css";

const ManageOrganizations = () => {
  const context = useContext(UserContext);
  const [parentOrganizations, setParentOrganizations] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const {
    userid: { userid },
    username: { username },
    token: { token },
  } = context;
  const [selectedOrganizationId, setSelectedOrganizationId] = useState("");
  const [mode, setMode] = useState("create");
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    url: "",
    parentOrganization: "",
    industry: "",
  });
  const [errors, setErrors] = useState({});

  const clearForm = () => {
    setFormData({
      name: "",
      description: "",
      url: "",
      parentOrganization: "",
      industry: "",
    });
    setErrors({});
  };

  const handleModeChange = (newMode) => {
    setMode(newMode);
    if (newMode === "create") {
      clearForm();
      setSelectedOrganizationId("");
    }
  };

  const validateForm = () => {
    if (mode === "delete") return true;
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Organization name is required";
    }
    if (
      !formData.url.match(
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/
      )
    ) {
      newErrors.url = "Invalid URL format";
    }
    if (!formData.industry) {
      newErrors.industry = "Please select an industry";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    if (mode === "delete") {
      setModalMessage("The organization/client was deleted");
      setShowModal(true);
      return;
    }

    const transactionType = mode === "create" ? "createClient" : "updateClient";

    const bodyData = {
      transactiontype: transactionType,
      clientid: selectedOrganizationId,
      name: formData.name,
      description: formData.description,
      url: formData.url,
      parentclientid: formData.parentOrganization,
      industryid: formData.industry,
      userid: userid,
      username: username,
      token: token,
    };

    try {
      const response = await fetchUtility(bodyData, "clientmanagement");
      await fetchParentOrganizations();
      if (mode === "create") {
        clearForm();
      }
      setModalMessage(JSON.stringify(response, null, 2));
      setShowModal(true);
    } catch (error) {
      setModalMessage(`Error: ${error.message}`);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const handleOrganizationSelect = useCallback((organizationId) => {
    setSelectedOrganizationId(organizationId);
    if (mode === "create") {
      setMode("modify");
    }
    fetchOrganizationInfo(organizationId);
  }, [mode]);

  const fetchOrganizationInfo = useCallback(async (orgId) => {
    if (!orgId) return;

    const bodyData = {
      transactiontype: "getClientInfo",
      username: username,
      userid: userid,
      clientid: orgId,
      token: token,
    };

    try {
      const responseData = await fetchUtility(bodyData, "clientmanagement");
      if (responseData && responseData[0]) {
        setFormData({
          name: responseData[0].name || "",
          description: responseData[0].description || "",
          url: responseData[0].url || "",
          parentOrganization: responseData[0].client2parentclient || "",
          industry: responseData[0].client2industry || "",
        });
      }
    } catch (error) {
      console.error("Error fetching organization information:", error);
    }
  }, [userid, username, token]);

  const fetchParentOrganizations = useCallback(async () => {
    const bodyData = {
      transactiontype: "getClients",
      userid: userid,
      username: username,
      token: token,
    };

    try {
      const response = await fetchUtility(bodyData, "clientmanagement");
      if (Array.isArray(response)) {
        setParentOrganizations(response);
      } else {
        setParentOrganizations([]);
      }
    } catch (error) {
      setParentOrganizations([]);
    }
  }, [userid, username, token]);

  const fetchIndustries = useCallback(async () => {
    const bodyData = {
      transactiontype: "getIndustries",
      userid: userid,
      username: username,
      token: token,
    };

    try {
      const response = await fetchUtility(bodyData, "clientmanagement");
      if (Array.isArray(response)) {
        setIndustries(response);
      } else {
        setIndustries([]);
      }
    } catch (error) {
      setIndustries([]);
    }
  }, [userid, username, token]);

  useEffect(() => {
    fetchParentOrganizations();
    fetchIndustries();
  }, [fetchParentOrganizations, fetchIndustries]);

  return (
    <div className={styles.manageOrganizationsContainer}>
      <div className={styles.treeViewContainer}>
        <TreeView
          transactiontype="getClients"
          onNodeSelect={handleOrganizationSelect}
        />
      </div>

      <div className={styles.formContainer}>
        <div className={styles.radioButtons}>
          <label>
            <input
              type="radio"
              value="create"
              checked={mode === "create"}
              onChange={() => handleModeChange("create")}
            />
            Create Organization
          </label>
          <label>
            <input
              type="radio"
              value="modify"
              checked={mode === "modify"}
              onChange={() => handleModeChange("modify")}
            />
            Modify Organization
          </label>
          <label>
            <input
              type="radio"
              value="delete"
              checked={mode === "delete"}
              onChange={() => handleModeChange("delete")}
            />
            Delete Organization
          </label>
        </div>

        <form className={styles.formContent} onSubmit={handleSubmit}>
          <h2>Organization Management</h2>
          <input
            type="text"
            name="name"
            placeholder="Organization Name"
            value={formData.name}
            onChange={handleChange}
            readOnly={mode === "delete"}
          />
          {errors.name && <span className={styles.error}>{errors.name}</span>}
          <textarea
            name="description"
            placeholder="Description"
            value={formData.description}
            onChange={handleChange}
            readOnly={mode === "delete"}
          />
          <input
            type="text"
            name="url"
            placeholder="URL"
            value={formData.url}
            onChange={handleChange}
            readOnly={mode === "delete"}
          />
          {errors.url && <span className={styles.error}>{errors.url}</span>}
          <select
            name="parentOrganization"
            value={formData.parentOrganization}
            onChange={handleChange}
            disabled={mode === "delete"}
          >
            <option value="">Select Parent Organization ...</option>
            {parentOrganizations.map((client) => (
              <option key={client.clientid} value={client.clientid}>
                {client.client}
              </option>
            ))}
          </select>
          <select
            name="industry"
            value={formData.industry}
            onChange={handleChange}
            disabled={mode === "delete"}
          >
            <option value="">Select Industry ...</option>
            {industries.map((industry) => (
              <option key={industry.industryid} value={industry.industryid}>
                {industry.name}
              </option>
            ))}
          </select>
          {errors.industry && (
            <span className={styles.error}>{errors.industry}</span>
          )}

          <input
            type="submit"
            value={
              mode === "create"
                ? "Create Organization"
                : mode === "modify"
                ? "Update Organization"
                : "Delete Organization"
            }
          />
        </form>
      </div>
      <ModalMessage
        show={showModal}
        onHide={handleCloseModal}
        message={modalMessage}
      />
    </div>
  );
};

export default ManageOrganizations;