import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../utilities/UserContext";
import fetchUtility from "../../utilities/fetchUtilities";
import getRiskIcon from "../../utilities/manageIcons";
import "./FormReports.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";

// Registrar los componentes necesarios de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  BarElement
);

export const DiagnosisReport = (props) => {
  const context = useContext(UserContext);
  const [responseDiagnosis, setDiagnosis] = useState([]);
  const [responseGenelDignosis, setGenelDignosis] = useState([]);
  const [responseRisks, setRisks] = useState({});
  const [responseGenelDignosisbySection, setGenelDignosisbySection] = useState(
    []
  );
  const {
    userid: { userid },
    username: { username },
    token: { token },
  } = context;
  
  useEffect(() => {
    const fetchData = async () => {
      let transactionType = "getDiagnosis";
      let bodyData = {
        transactiontype: transactionType,
        assessmentid: props.assessmentid,
        userid: userid,
        username: username,
        token: token,
      };
      
      try {
        const responseData = await fetchUtility(bodyData, "reports");
        setDiagnosis(responseData);
      } catch (error) {
        console.error("Error al obtener los datos getDiagnosis:", error);
      }

      transactionType = "getGenelDignosis";

      bodyData = {
        transactiontype: transactionType,
        assessmentid: props.assessmentid,
        userid: userid,
        username: username,
        token: token,
      };

      try {
        const responseData = await fetchUtility(bodyData, "reports");
        setGenelDignosis(responseData);
      } catch (error) {
        console.error("Error al obtener los datos getGenelDignosis:", error);
      }

      transactionType = "getGenelDignosisbySection";
      
      bodyData = {
        transactiontype: transactionType,
        assessmentid: props.assessmentid,
        userid: userid,
        username: username,
        token: token,
      };

      try {
        const responseData = await fetchUtility(bodyData, "reports");
        setGenelDignosisbySection(responseData);
      } catch (error) {
        console.error(
          "Error al obtener los datos getGenelDignosisbySection:",
          error
        );
      }
    };
    fetchData();
  }, [context]);

  // Nueva función para obtener los riesgos asociados con un questionid
  const fetchRisksForQuestion = async (questionId) => {
    let transactionType = "getRiskRecommendation";

    let bodyData = {
      transactiontype: transactionType,
      questionid: questionId,
      assessmentid: props.assessmentid,
      userid: userid,
      username: username,
      token: token,
    };
    
    try {
      const responseData = await fetchUtility(bodyData, "reports");
      return responseData;
    } catch (error) {
      console.error("Error al obtener los riesgos:", error);
      return [];
    }
  };

  // UseEffect para cargar los riesgos para cada diagnóstico
  useEffect(() => {
    if (responseDiagnosis.length > 0) {
      const fetchAllRisks = async () => {
        const risksPromises = responseDiagnosis.map(async (lsDiagnosis) => {
          const risks = await fetchRisksForQuestion(lsDiagnosis.questionid);
          return { questionid: lsDiagnosis.questionid, risks };
        });

        const resolvedRisks = await Promise.all(risksPromises);

        const risksMap = resolvedRisks.reduce((acc, { questionid, risks }) => {
          acc[questionid] = risks;
          return acc;
        }, {});

        setRisks(risksMap);
      };

      fetchAllRisks();
    }
  }, [responseDiagnosis]);

  const groupedDiagnoses = responseDiagnosis.reduce((acc, lsDiagnosis) => {
    if (!acc[lsDiagnosis.section]) {
      acc[lsDiagnosis.section] = [];
    }
    acc[lsDiagnosis.section].push(lsDiagnosis);
    return acc;
  }, {});

  const getRiskLevel = (balance) => {
    if (balance >= 0 && balance < 28) {
      return "Bajo";
    } else if (balance >= 28 && balance < 56) {
      return "Medio";
    } else if (balance >= 56 && balance < 84) {
      return "Alto";
    } else if (balance >= 84 && balance <= 100) {
      return "Crítico";
    } else {
      return "Desconocido"; // Por si acaso el valor no cae en ninguno de los rangos esperados
    }
  };

  return (
    <>
      <div div className="charts-container">
        <div className="divTable">
          <h4>Nivel de riesgo general del assessment</h4>
          <table className="table table-bordered">
            <thead className="table-danger text-center">
              <tr>
                <th scope="col">Assessment Type</th>
                <th scope="col">Risk Level</th>
              </tr>
            </thead>
            <tbody>
              {responseGenelDignosis.map((lsGenelDignosis, lsGDindex) => (
                <tr key={lsGDindex}>
                  <th scope="row" className="text-start">
                    {lsGenelDignosis.assessmentype}
                  </th>
                  <td>
                    {" "}
                    <img
                      className="imgGeneralDiagnosis"
                      src={getRiskIcon(getRiskLevel(lsGenelDignosis.balance))}
                      alt={getRiskIcon(getRiskLevel(lsGenelDignosis.balance))}
                    />
                    <br />
                    {getRiskLevel(lsGenelDignosis.balance)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="divTable">
          <h4>Nivel de riesgo del assessment por sección</h4>
          <table className="table table-bordered">
            <thead className="table-danger text-center">
              <tr>
                <th scope="col">Section</th>
                <th scope="col">Risk Level</th>
              </tr>
            </thead>
            <tbody>
              {responseGenelDignosisbySection.map(
                (lsGenelDignosisbySection, lsGDSindex) => (
                  <tr key={lsGDSindex}>
                    <th scope="row" className="text-start">
                      {lsGenelDignosisbySection.section}
                    </th>
                    <td>
                      <img
                        className="imgGeneralDiagnosis"
                        src={getRiskIcon(
                          getRiskLevel(lsGenelDignosisbySection.balance)
                        )}
                        alt={getRiskIcon(
                          getRiskLevel(lsGenelDignosisbySection.balance)
                        )}
                      />
                      <br />
                      {getRiskLevel(lsGenelDignosisbySection.balance)}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        <br />
      </div>
      <br />
      <div className="divGeneralTable">
        {Object.keys(groupedDiagnoses).map((sectionName, sectionIndex) => (
          <div key={sectionIndex}>
            <h2>
              <label>{sectionName}</label>
            </h2>
            <table className="table table-bordered">
              <thead className="table-danger">
                <tr>
                  <th>Objective</th>
                  <th>Risk</th>
                  <th>Risk description</th>
                  <th>Recommendation</th>
                </tr>
              </thead>
              <tbody>
                {groupedDiagnoses[sectionName].map((lsDiagnosis, index) => {
                  let previousRisk = "";
                  let riskCounter = 0;

                  return (
                    <tr key={index}>
                      <th scope="row" className="text-start">
                        {lsDiagnosis.objective}
                      </th>
                      <td>
                        <img
                          className="imgGeneralDiagnosis"
                          src={getRiskIcon(
                            getRiskLevel(lsDiagnosis.riskcriticality)
                          )}
                          alt={getRiskIcon(
                            getRiskLevel(lsDiagnosis.riskcriticality)
                          )}
                        />
                        {getRiskLevel(lsDiagnosis.riskcriticality)}
                      </td>
                      <td className="text-start">
                        {responseRisks[lsDiagnosis.questionid] &&
                          responseRisks[lsDiagnosis.questionid].map(
                            (lsRisk, riskIndex) => {
                              if (lsRisk.risk !== previousRisk) {
                                previousRisk = lsRisk.risk;
                                riskCounter++; // Incrementar el contador de riesgos solo si el riesgo cambia
                                return (
                                  <React.Fragment key={riskIndex}>
                                    <strong>{`Riesgo ${riskCounter}:`}</strong>{" "}
                                    {lsRisk.risk}
                                    <br />
                                  </React.Fragment>
                                );
                              }
                              return null; // No renderiza si el riesgo es igual al anterior
                            }
                          )}
                      </td>
                      <td className="text-start">
                        {responseRisks[lsDiagnosis.questionid] &&
                          responseRisks[lsDiagnosis.questionid].map(
                            (lsRisk, riskIndex) => {
                              if (lsRisk.risk === previousRisk) {
                                return (
                                  <React.Fragment key={riskIndex}>
                                    <strong> - </strong>{" "}
                                    {lsRisk.recommendation}
                                    <br />
                                  </React.Fragment>
                                );
                              } else {
                                previousRisk = lsRisk.risk;
                                riskCounter++; // Incrementar el contador de riesgos solo si el riesgo cambia
                                return (
                                  <React.Fragment key={riskIndex}>
                                    <strong> - </strong>{" "}
                                    {lsRisk.recommendation}
                                    <br />
                                  </React.Fragment>
                                );
                              }
                            }
                          )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </>
  );
};

export default DiagnosisReport;
