// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TreeView_treeView__8A4FZ {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  max-height: 500px;
  overflow-y: auto;
}

.TreeView_treeItem__lT8Pq {
  margin: 5px 0;
  text-align: left;
}

.TreeView_treeItemContent__Vtm1M {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 0;
}

.TreeView_treeItemContent__Vtm1M:hover {
  background-color: #e9ecef;
}

.TreeView_toggle__HDLBC {
  margin-right: 5px;
  font-size: 12px;
  width: 15px;
  display: inline-block;
  text-align: center;
}

.TreeView_label__uIpwt {
  flex: 1 1;
}

.TreeView_childrenContainer__cadoY {
  margin-top: 5px;
}

.TreeView_icon__\\+Utye {
  width: 28px;
  height: 28px;
  margin-right: 5px;
  vertical-align: middle;
}

.TreeView_treeItem__lT8Pq:not(:first-child) .TreeView_icon__\\+Utye {
  width: 22px;
  height: 22px;
}`, "",{"version":3,"sources":["webpack://./src/components/setup/TreeView.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,WAAW;EACX,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".treeView {\n  font-family: Arial, sans-serif;\n  background-color: #f8f9fa;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n  padding: 10px;\n  max-height: 500px;\n  overflow-y: auto;\n}\n\n.treeItem {\n  margin: 5px 0;\n  text-align: left;\n}\n\n.treeItemContent {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  padding: 5px 0;\n}\n\n.treeItemContent:hover {\n  background-color: #e9ecef;\n}\n\n.toggle {\n  margin-right: 5px;\n  font-size: 12px;\n  width: 15px;\n  display: inline-block;\n  text-align: center;\n}\n\n.label {\n  flex: 1;\n}\n\n.childrenContainer {\n  margin-top: 5px;\n}\n\n.icon {\n  width: 28px;\n  height: 28px;\n  margin-right: 5px;\n  vertical-align: middle;\n}\n\n.treeItem:not(:first-child) .icon {\n  width: 22px;\n  height: 22px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"treeView": `TreeView_treeView__8A4FZ`,
	"treeItem": `TreeView_treeItem__lT8Pq`,
	"treeItemContent": `TreeView_treeItemContent__Vtm1M`,
	"toggle": `TreeView_toggle__HDLBC`,
	"label": `TreeView_label__uIpwt`,
	"childrenContainer": `TreeView_childrenContainer__cadoY`,
	"icon": `TreeView_icon__+Utye`
};
export default ___CSS_LOADER_EXPORT___;
