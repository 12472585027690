import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../utilities/UserContext";
import fetchUtility from "../../utilities/fetchUtilities";
import styles from "./TreeView.module.css";
import UsersIcon from "./img/Users.svg";
import SingleUserIcon from "./img/Single-User.svg";
import OrganizationsIcon from "./img/Organizations.png";
import SingleOrganizationIcon from "./img/Single-Organization.png";

const TreeItem = ({ transactiontype, label, id, children, onNodeSelect, level = 0, isRoot = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const getIcon = () => {
    if (transactiontype === "getUsers") {
      return isRoot ? UsersIcon : SingleUserIcon;
    } else if (transactiontype === "getClients") {
      return isRoot ? OrganizationsIcon : SingleOrganizationIcon;
    }
  };

  const getAltText = () => {
    if (transactiontype === "getUsers") {
      return isRoot ? "Users" : "User";
    } else if (transactiontype === "getClients") {
      return isRoot ? "Organizations" : "Organization";
    }
  };

  return (
    <div className={styles.treeItem} style={{ marginLeft: `${level * 20}px` }}>
      <div 
        className={styles.treeItemContent}
        onClick={() => {
          if (!children && onNodeSelect) onNodeSelect(id);
        }}
      >
        {children && (
          <span className={styles.toggle} onClick={handleToggle}>
            {isOpen ? '▼' : '▶'}
          </span>
        )}
        <img 
          src={getIcon()} 
          alt={getAltText()} 
          className={styles.icon} 
        />
        <span className={styles.label}>{label}</span>
      </div>
      {isOpen && children && (
        <div className={styles.childrenContainer}>
          {children.map((child, index) => (
            <TreeItem
              key={index}
              {...child}
              onNodeSelect={onNodeSelect}
              level={level + 1}
              transactiontype={transactiontype}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const TreeView = ({ onNodeSelect, transactiontype }) => {
  const context = useContext(UserContext);
  const [items, setItems] = useState([]);
  const { userid: { userid }, username: { username }, token: { token } } = context;

  useEffect(() => {
    const fetchItems = async () => {
      const bodyData = {
        transactiontype,
        userid,
        username,
        token,
      };
      //console.log("********************* " + bodyData.transactiontype + "*******************************")
      try {
        const responseData = await fetchUtility(bodyData, bodyData.transactiontype === "getUsers" ? "manageuser" : "clientmanagement");
        setItems(responseData);
      } catch (error) {
        console.error("Error getting data:", error);
      }
    };
    fetchItems();
  }, [userid, username, token, transactiontype]);

  const getRootLabel = () => {
    return transactiontype === "getUsers" ? "Users" : "Organizations";
  };

  const treeData = [
    {
      label: getRootLabel(),
      children: items.map(item => ({
        label: item.username || item.client,
        id: item.userid || item.clientid
      })),
      isRoot: true
    }
  ];

  return (
    <div className={styles.treeView}>
      {treeData.map((item, index) => (
        <TreeItem 
          key={index} 
          {...item} 
          onNodeSelect={onNodeSelect}
          transactiontype={transactiontype}
        />
      ))}
    </div>
  );
};

export default TreeView;