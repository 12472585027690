import React from "react";
import styles from "./Home.module.css";
import logo from "../../logo2.png";
// Asume que estas son tus imágenes, reemplaza las rutas según sea necesario
import msspImg from "../../mssp.png";
import image2 from "../../ia.png";

export const Home = () => {
  return (
    <div className={styles.HomeContainer}>
      <br />
      <br />
      <img src={logo} className={styles.App - logo} width="30%" alt="logo" />
      <br />
      <div className={styles.IntroText}>
        In an increasingly interconnected digital world, cybersecurity has
        become a paramount concern for organizations of all sizes and sectors.
        With the proliferation of cyber threats and the complexity in security
        management, the need for advanced solutions and managed services arises.
        Managed Security Service Providers (MSSPs) play a crucial role in the
        market, offering enterprises the expertise and tools necessary to secure
        their infrastructures and critical data. In this context, the
        incorporation of technologies like Artificial Intelligence (AI) and the
        solutions provided by industry leaders like <span>Fortinet</span>, mark
        a significant milestone in the evolution of cybersecurity.{" "}
        <span>Fortinet</span>, with its comprehensive and AI-driven approach,
        offers a robust platform that empowers both organizations and MSSPs,
        setting a high standard in the management and mitigation of cyber risks,
        and therefore, plays a vital role in defining the future of
        cybersecurity in the global market.
      </div>
      <div className={styles.BoxContainer}>
        <div className={styles.Box}>
          <img src={msspImg} alt="Imagen 1" />
          <h2>MSSP Maturity Assessment</h2>
          <div>
            In an increasingly interconnected digital world, cybersecurity has
            become fundamental for organizations of all sizes and sectors.
            Managed Security Service Providers (MSSPs) play a crucial role in
            the market, offering enterprises the expertise and tools necessary
            to secure their infrastructures and critical data. In this context,
            solutions provided by industry leaders like Fortinet mark a
            significant milestone in the evolution of cybersecurity. Fortinet
            stands out in this domain, particularly through its MSSP Maturity
            Assessment, which helps clients understand their cybersecurity
            maturity level. This assessment, aligned with recognized process
            frameworks like ITIL 4 and eTOM, and standards like NIST, provides a
            detailed analysis of the MSSP's processes, technology, and
            offerings. With our specialization in these assessments, we are
            equipped to assist our clients in closely collaborating with MSSPs
            to strengthen their security strategies, enabling effective
            management and mitigation of cyber risks. This ultimately positions
            Fortinet as a strategic ally in defining and advancing the future
            cybersecurity agenda in the global market.
          </div>
        </div>
        <div className={styles.Box}>
          <img src={image2} alt="Imagen 2" />
          <h2>Fortinet & IA</h2>
          <div>
            Artificial Intelligence (AI) has become a cornerstone in the
            cybersecurity strategy of many organizations, providing advanced
            capabilities to anticipate, identify, and mitigate threats in real
            time. Fortinet has recognized this trend and has significantly
            invested in integrating AI across its extensive portfolio of
            security products. Fortinet's products with AI capabilities not only
            strengthen the security posture of organizations, but also enable
            them to follow the risk management cycle outlined by NIST (National
            Institute of Standards and Technology), covering the phases of
            Identify, Protect, Detect, Respond, and Recover. Additionally, at
            Fortinet, we specialize in assisting our clients in creating an
            architecture that incorporates AI elements in the best way possible,
            maximizing their current capabilities, resulting in a more proactive
            and robust cybersecurity approach.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
