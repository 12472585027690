import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../utilities/UserContext";
import FormReports from "../Reports/FormReports";
import fetchUtility from "../../utilities/fetchUtilities";
import "./Reports.css";

export const Reports = () => {
  const context = useContext(UserContext);
  const [responseClients, setResponseClients] = useState([]);
  const [assessmentTypes, setAssessmentTypes] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [selectedClientid, setSelectedClientid] = useState("");
  const [selectedAssessmentTypeid, setSelectedAssessmentTypeid] = useState("");
  const [selectedAssessment, setSelectedAssessment] = useState("");
  const {
    userid: { userid },
    username: { username },
    token: { token },
  } = context;

  useEffect(() => {
    const fetchData = async () => {
      const transactionType = "getClients";

      const bodyData = {
        transactiontype: transactionType,
        userid: userid,
        username: username,
        token: token,
      };

      try {
        const responseClients = await fetchUtility(
          bodyData,
          "clientmanagement"
        );
        setResponseClients(responseClients);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };

    fetchData();
  }, [context]);

  const handleClientChange = async (event) => {
    const selectedClientid = event.target.value;
    setSelectedClientid(selectedClientid);

    if (selectedClientid) {
      const bodyData = {
        transactiontype: "getAssessmenttypebyClientid",
        userid: userid,
        username: username,
        clientid: selectedClientid,
        token: token,
      };

      const response = await fetchUtility(bodyData, "reports");
      setAssessmentTypes(response);
      setAssessments([]);
      setSelectedAssessmentTypeid("");
      setSelectedAssessment("");
    } else {
      setAssessmentTypes([]);
      setAssessments([]);
      setSelectedAssessmentTypeid("");
      setSelectedAssessment("");
    }
  };

  const handleAssessmentTypeChange = async (event) => {
    const assessmenttypeid = event.target.value;
    setSelectedAssessmentTypeid(assessmenttypeid);
    const clientid = selectedClientid;

    if (assessmenttypeid) {
      const bodyData = {
        transactiontype: "getAssessmentbyClientAsessmenmttypeid",
        userid: userid,
        username: username,
        clientid: clientid,
        assessmenttypeid: assessmenttypeid,
        token: token,
      };

      const response = await fetchUtility(bodyData, "reports");
      setAssessments(response);
      setSelectedAssessment("");
    } else {
      setAssessments([]);
      setSelectedAssessment("");
    }
  };

  const handleAssessmentChange = async (event) => {
    const assessment = event.target.value;
    setSelectedAssessment(assessment);
  };

  return (
    <div className="w-auto p-3 Reports">
      <div style={{ display: "flex", gap: "10px" }}>
        <select
          name="selectClient"
          className="form-select"
          aria-label="Default select example"
          style={{ width: "300px", height: "40px" }}
          onChange={handleClientChange}
        >
          <option value="">Select Client ...</option>
          {responseClients.map((lsClients) => (
            <option key={lsClients.clientid} value={lsClients.clientid}>
              {lsClients.client}
            </option>
          ))}
        </select>

        <select
          name="selectAssessmenttype"
          className="form-select"
          aria-label="Default select example"
          style={{ width: "400px", height: "40px" }}
          disabled={!selectedClientid}
          onChange={handleAssessmentTypeChange}
        >
          <option value="">Assessment type</option>
          {assessmentTypes.map((type) => (
            <option key={type.assessmentypeid} value={type.assessmentypeid}>
              {type.assessmenttype}
            </option>
          ))}
        </select>

        <select
          name="selectAssessmenmt"
          className="form-select"
          aria-label="Default select example"
          style={{ width: "400px", height: "40px" }}
          disabled={!selectedAssessmentTypeid || !selectedClientid}
          onChange={handleAssessmentChange}
        >
          <option value="">Assessment</option>
          {assessments.map((lsAssessment) => (
            <option
              key={lsAssessment.assessmentid}
              value={lsAssessment.assessmentid}
            >
              {lsAssessment.assessment}
            </option>
          ))}
        </select>
      </div>

      <div className="w-auto p-3">
        {selectedAssessment && (
          <FormReports
            clientid={selectedClientid}
            assessmenttypeid={selectedAssessmentTypeid}
            assessmentid={selectedAssessment}
            key={selectedAssessment}
          />
        )}
      </div>
    </div>
  );
};

export default Reports;
