import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Registrar componentes necesarios
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const BarChart = ({ data }) => {
  // Extraer datos del JSON
  const labels = data.map(item => item['Section']);
  const achievedPoints = data.map(item => item['Achieved Points']);
  const sectionPoints = data.map(item => item['Section Points']);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Achieved points",
        data: achievedPoints,
        backgroundColor: "rgba(192, 8, 0, 0.2)",
        borderColor: "rgba(192, 8, 0, 1)",
        borderWidth: 1,
      },
      {
        label: "Section points",
        data: sectionPoints,
        backgroundColor: "rgba(125, 211, 214, 0.2)",
        borderColor: "rgba(125, 211, 214, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        ticks: {
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false
      }
    }
  };

  return (
    <div className="chart dataCard barChart" style={{ height: '400px' }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default BarChart;