// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}



.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Coloca elementos a ambos lados */
  padding: 0 20px; /* Agrega un espacio a los lados para el logo */
  font-size:100%;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-header-text {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,oBAAoB;AACtB;;;;AAIA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,8BAA8B,EAAE,mCAAmC;EACnE,eAAe,EAAE,+CAA+C;EAChE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".App {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  text-align: center;\n}\n\n.App-logo {\n  height: 5vmin;\n  pointer-events: none;\n}\n\n\n\n.App-header {\n  background-color: #282c34;\n  min-height: 10vh;\n  display: flex;\n  align-items: center;\n  justify-content: space-between; /* Coloca elementos a ambos lados */\n  padding: 0 20px; /* Agrega un espacio a los lados para el logo */\n  font-size:100%;\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.App-header-text {\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
