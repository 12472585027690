export default {
  API_URLS: {
   assessment: "https://node-sqlserver-restapi.azurewebsites.net/assessment",
    usermanagement:
      "https://node-sqlserver-restapi.azurewebsites.net/usermanagement",
    signin:
      "https://node-sqlserver-restapi.azurewebsites.net/usermanagement/signin",
    manageuser:
      "https://node-sqlserver-restapi.azurewebsites.net/usermanagement/manageuser",
    reports: "https://node-sqlserver-restapi.azurewebsites.net/reports",
    createupdateusr:
      "https://node-sqlserver-restapi.azurewebsites.net/usermanagement/createuser",
      clientmanagement: "https://node-sqlserver-restapi.azurewebsites.net/clientmanagement",
    

    /*assessment: "http://localhost:4000/assessment",
    usermanagement: "http://localhost:4000/usermanagement",
    signin: "http://localhost:4000/usermanagement/signin",
    manageuser: "http://localhost:4000/usermanagement/manageuser",
    reports: "http://localhost:4000/reports",
    createupdateusr: "http://localhost:4000/usermanagement/createuser",
    clientmanagement: "http://localhost:4000/clientmanagement",*/
  },
  
  log_level: process.env.LOG_LEVEL,
};
