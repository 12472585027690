import React, { useState, useEffect } from "react";
import fetchUtility from "../../utilities/fetchUtilities";
import styles from "./Login.module.css";
import UserContext from "../../utilities/UserContext";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const userContext = React.useContext(UserContext);

  const [form, setForm] = useState({
    username: "",
    password: "",
    code: "",
    responseLogin: [],
  });

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [is2FA, setIs2FA] = useState(false);
  const [countdown, setCountdown] = useState(300); // 5 minutes in seconds

  useEffect(() => {
    let timer;
    if (is2FA && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIs2FA(false);
      setErrorMsg("Code expired. Please try again.");
      setError(true);
    }
    return () => clearInterval(timer);
  }, [is2FA, countdown]);

  const handleOnChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = async (e) => {
    try {
      const bodyDataLogin = {
        signintype: is2FA ? "Two-Factor Authentication" : "Standard",
        username: form.username,
        password: form.password,
        code: form.code,
      };
      //console.log("bodyDataLogin: " + JSON.stringify(bodyDataLogin));

      const responseLogin = await fetchUtility(bodyDataLogin, "signin");

      //console.log(responseLogin);
      //console.log("is2FA: " + is2FA);
      //console.log("responseLogin.isValidUser: " + responseLogin.isValidUser);
      //console.log("responseLogin.ontimeocode: " + responseLogin.ontimeocode);
      //console.log("responseLogin.signintype: " + responseLogin.signintype);

      if (
        responseLogin.isValidUser === true &&
        responseLogin.signintype === "Standard"
      ) {
        // Standard login success
        setUserContext(responseLogin);
        navigate("/Home");
      } else if (
        is2FA === false &&
        responseLogin.isValidUser === true &&
        responseLogin.signintype === "Two-Factor Authentication"
      ) {
        // 2FA required
        setIs2FA(true);
        setCountdown(300); // Reset countdown when 2FA is triggered
      } else if (
        is2FA &&
        responseLogin.isValidUser === true &&
        responseLogin.ontimeocode === true &&
        responseLogin.signintype === "Two-Factor Authentication"
      ) {
        // 2FA validation success
        setUserContext(responseLogin);
        navigate("/Home");
      } else {
        setErrorMsg("Invalid credentials");
        setError(true);
      }
    } catch (error) {
      setErrorMsg("Error getting data: " + error);
      setError(true);
    }
  };

  const setUserContext = (responseLogin) => {
    userContext.setUsername({ username: form.username });
    userContext.setUser({ user: responseLogin.name });
    userContext.setUserId({ userid: responseLogin.userid });
    userContext.setClientId({ clientid: responseLogin.companyid });
    userContext.setSigned({ signed: responseLogin.isValidUser });
    userContext.setEnableaccesscode({
      enableaccesscode: responseLogin.enableaccesscode,
    });
    userContext.setToken({ token: responseLogin.token });
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <React.Fragment>
      <div className={styles.wrapper + " " + styles.fadeInDown}>
        <div className={styles.formContent} id="formContent">
          <div className={styles.fadeIn + " " + styles.first}>
            <br />
            <img src="./logo.png" width="150" alt="Logo" />
            <br />
            <br />
            <br />
          </div>
          <form className={styles.Login} onSubmit={(e) => e.preventDefault()}>
            <input
              type="text"
              className={styles.fadeIn + " " + styles.second}
              name="username"
              placeholder="username"
              onChange={handleOnChange}
              disabled={is2FA}
            />
            <input
              type="password"
              className={styles.fadeIn + " " + styles.third}
              name="password"
              placeholder="password"
              onChange={handleOnChange}
              disabled={is2FA}
            />
            {is2FA && (
              <>
                <input
                  type="text"
                  className={styles.fadeIn + " " + styles.third}
                  name="code"
                  placeholder="code"
                  onChange={handleOnChange}
                />
                <p className={styles.codeMessage}>
                  Enter the code that was sent to your email, the code is valid
                  for {formatTime(countdown)}
                </p>
              </>
            )}
            <input
              type="submit"
              className={styles.fadeIn + " " + styles.fourth}
              value={is2FA ? "Validate Code" : "Log In"}
              onClick={handleClick}
            />
          </form>

          {error === true && (
            <div className="alert alert-danger" role="alert">
              {errorMsg}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
