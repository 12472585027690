import React, { useState, useEffect, useContext } from "react";
import randomColor from "randomcolor";
import UserContext from "../../utilities/UserContext";
import fetchUtility from "../../utilities/fetchUtilities";
import RingLoader from "react-spinners/RingLoader";
import DiagnosisReport from "./DiagnosisReport";
import ReportPDF from "./ReportPDF";
import DynamicTable from "./DynamicTable";
import BarChart from "./BarChart";
import RadarChart from "./RadarChart";
import BubbleChart from "./BubbleChart";
import "./FormReports.css";

import {
  PDFDownloadLink,
} from "@react-pdf/renderer";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Bar, Radar, Bubble } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(ChartDataLabels);

// Registrar los componentes necesarios de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  BarElement
);

export const FormReports = (props) => {
  const context = useContext(UserContext);
  const [responseEvaluationSection, setEvaluationSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [responsebubbleData, setbubbleData] = useState([]);
  const {
    userid: { userid },
    username: { username },
    token: { token },
  } = context;

  useEffect(() => {
    let fetchData = async () => {
      const transactionType = "getEvaluationSection";

      const bodyData = {
        transactiontype: transactionType,
        clientid: props.clientid,
        assessmenttypeid: props.assessmenttypeid,
        assessmentid: props.assessmentid,
        userid: userid,
        username: username,
        token: token,
      };

      try {
        const responseData = await fetchUtility(bodyData, "reports");
        setEvaluationSections(responseData);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };
    fetchData();

    fetchData = async () => {
      const transactionType = "getBubbleData";

      const bodyData = {
        transactiontype: transactionType,
        assessmentid: props.assessmentid,
        userid: userid,
        username: username,
        token: token,
      };

      try {
        const responseData = await fetchUtility(bodyData, "reports");
        setbubbleData(responseData);
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };
    fetchData();
  }, [context]);

  // Agrupamos los datos por sección
  const groupedData = responsebubbleData.reduce((acc, lsbubbleData) => {
    // Si la sección no existe en el acumulador, la creamos
    if (!acc[lsbubbleData.section]) {
      acc[lsbubbleData.section] = {
        labels: [], // Etiquetas para los elementos de la sección
        data: [], // Puntos de datos de la sección
      };
    }

    // Agregar el elemento a la lista de etiquetas de la sección (evitar duplicados)
    if (!acc[lsbubbleData.section].labels.includes(lsbubbleData.element)) {
      acc[lsbubbleData.section].labels.push(lsbubbleData.element);
    }

    // Agregar el punto de datos al conjunto de datos de la sección
    acc[lsbubbleData.section].data.push({
      x: lsbubbleData.x,
      y: lsbubbleData.y,
      r: lsbubbleData.r,
      label: lsbubbleData.element,
    });

    return acc;
  }, {});

  // Crear el dataset para cada sección con la estructura deseada
  const bubbleDatasets = Object.keys(groupedData).map((section) => ({
    labels: groupedData[section].labels, // Etiquetas de todos los elementos de la sección
    datasets: [
      {
        label: section, // Nombre de la sección como etiqueta del dataset
        data: groupedData[section].data, // Puntos de datos para esta sección
        backgroundColor:
          section === "General"
            ? "rgba(192, 8, 0, 1)" // Color específico para 'General'
            : randomColor({ luminosity: "light", format: "rgba", alpha: 0.5 }), // Color dinámico para otras secciones
        borderColor:
          section === "General"
            ? "rgba(192, 8, 0, 1)" // Color de borde específico para 'General'
            : randomColor({ luminosity: "dark", format: "rgba", alpha: 1 }), // Color de borde dinámico para otras secciones
        borderWidth: 1,
      },
    ],
  }));

  // Configuración final para el gráfico de burbujas
  const bubbleDatasetConfig = {
    datasets: bubbleDatasets.flatMap((dataset) => dataset.datasets), // Unir todos los datasets en un solo array
  };

  // Configuración de opciones del gráfico
  const bubbleOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.raw.label || ""; // Muestra el label definido por responsebubbleData.element
            const xValue = context.raw.x;
            const yValue = context.raw.y;
            const rValue = context.raw.r;
            return `${label}: (x: ${xValue}, y: ${yValue}, r: ${rValue})`;
          },
        },
      },
      datalabels: {
        display: false, // Asegura que no se muestren etiquetas dentro de las burbujas
      },
    },
    elements: {
      point: {
        radius: function (context) {
          return context.raw.r; // Controla el tamaño del radio de las burbujas
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "IMPLEMENTATION ORDER", // Etiqueta personalizada para el eje X
        },
        ticks: {
          beginAtZero: true,
        },
      },
      y: {
        title: {
          display: true,
          text: "CRITICALITY", // Etiqueta personalizada para el eje Y
        },
        ticks: {
          beginAtZero: true,
        },
      },
    },
    maintainAspectRatio: false,
  };

  if (loading) {
    return (
      <>
        <div className="FormReportsLoading">
          LOADING...
          <br />
          <br />
          <RingLoader
            className="FormReportsLoading"
            color={"#000000"}
            loading={loading}
            size={150}
          />
          <br />
          <br />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="Table charts-container chart dataCard">
        <DynamicTable data={responseEvaluationSection} />
      </div>
      <br />
      <div className="chart dataCard">
        <div className="charts-container">
          <BarChart data={responseEvaluationSection} />
          <RadarChart data={responseEvaluationSection} />
        </div>
      </div>
      <br />
      <div className="chart dataCard">
        <BubbleChart data={responsebubbleData} />
      </div>
      <br />
      <DiagnosisReport
        client={props.clientid}
        assessmentid={props.assessmentid}
      />
      {/* <ReportPDF clientid = {props.clientid} assessmenttypeid = {props.assessmenttypeid}/> */}
      {loading ? (
        <p>Cargando datos del reporte...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <>
          <PDFDownloadLink
            document={
              <ReportPDF
                clientid={props.clientid}
                assessmenttypeid={props.assessmenttypeid}
                assessmentid={props.assessmentid}
                userid={userid}
                username={username}
                token={token}
              />
            }
            fileName="reporte_assessment.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Generando PDF..." : "Descargar Reporte PDF"
            }
          </PDFDownloadLink>
        </>
      )}
    </>
  );
};

export default FormReports;
