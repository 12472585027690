import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ManageUsers from "../setup/ManageUsers";
import ManageOrganizations from "../setup/ManageOrganizations";

export const Setup = () => {
  const [key, setKey] = useState("usermanagement");

  return (
    <div className="container">
      <div className="row">
        {/* Columna principal para las Tabs (pestañas) */}
        <div className="col-12">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
            style={{ backgroundColor: "lightgray" }} // Aplicamos el color gris aquí
          >
            <Tab eventKey="usermanagement" title="User Management">
              <div className="row">
                <div className="col-12">
                  <ManageUsers />
                </div>
              </div>
            </Tab>

            {/* Otras pestañas */}
            <Tab eventKey="organizations" title="Organization & Clients">
              <div className="col-12">
                <ManageOrganizations />
              </div>
            </Tab>
            <Tab eventKey="contact" title="Contact">
              <h1>Tab content for Contact</h1>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Setup;
