import React from 'react';
import PropTypes from 'prop-types';
//import './DynamicTable.css';

const DynamicTable = ({ data }) => {
    if (!data || data.length === 0) {
        return <div>No hay datos para mostrar</div>;
    }

    // Extraer las claves del primer objeto para usar como encabezados
    const headers = Object.keys(data[0]);

    // Función para formatear el nombre de la columna (opcional)
    const formatColumnName = (header) => {
        return header
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <div className="Table charts-container chart dataCard">
            <table className="table table-bordered">
                <thead className="table-danger text-center">
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index} scope="col">
                                {formatColumnName(header)}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {headers.map((header, colIndex) => (
                                <td key={`${rowIndex}-${colIndex}`} className="table-light">
                                    {row[header]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

DynamicTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DynamicTable;