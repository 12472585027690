import React, { useState, useEffect, useContext, useCallback } from "react";
import UserContext from "../../utilities/UserContext";
import fetchUtility from "../../utilities/fetchUtilities";
import TreeView from "./TreeView";
import ModalMessage from "../navegation/ModalMessage";
import styles from "./ManageUsers.module.css";

const ManageUsers = () => {
  const context = useContext(UserContext);
  const [responseOrganizations, setResponseOrganizations] = useState([]);
  const [signinTypes, setSigninTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const {
    userid: { userid },
    username: { username },
    token: { token },
  } = context;
  const [selectedUserId, setSelectedUserId] = useState("");
  const [mode, setMode] = useState("create");
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    organization: "",
    organizationid: "",
    username: "",
    userid: "",
    enableaccesscode: "",
    reports: false,
    assessments: false,
    setup: false,
    signintypeid: "",
  });
  const [errors, setErrors] = useState({});

  const clearForm = () => {
    setFormData({
      name: "",
      lastName: "",
      email: "",
      organization: "",
      organizationid: "",
      username: "",
      userid: "",
      enableaccesscode: "",
      reports: false,
      assessments: false,
      setup: false,
      signintypeid: "",
    });
    setErrors({});
  };

  const handleModeChange = (newMode) => {
    setMode(newMode);
    if (newMode === "create") {
      clearForm();
      setSelectedUserId("");
    }
  };

  const validateForm = () => {
    if (mode === "delete") return true;
    const newErrors = {};
    if (!formData.name.match(/^[a-zA-Z\s]+$/)) {
      newErrors.name = "Name should only contain letters and spaces";
    }
    if (!formData.lastName.match(/^[a-zA-Z\s]+$/)) {
      newErrors.lastName = "Last name should only contain letters and spaces";
    }
    if (!formData.username.match(/^[a-zA-Z0-9_]+$/)) {
      newErrors.username =
        "Username should only contain letters, numbers, and underscores";
    }
    if (!formData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      newErrors.email = "Invalid email format";
    }
    if (!formData.organizationid) {
      newErrors.organization = "Please select an organization";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    if (mode === "delete") {
      setModalMessage("The user was deleted");
      setShowModal(true);
      return;
    }

    const transactionType = mode === "create" ? "CreateUser" : "updateUser";

    const bodyData = {
      userid: userid,
      username: username,
      token: token,
      transactiontype: transactionType,
      manageuserid: selectedUserId,
      name: formData.name,
      lastname: formData.lastName,
      [mode === "create" ? "newusername" : "manageusername"]: formData.username,
      usremail: formData.email,
      clientid: parseInt(formData.organizationid),
      client: formData.organization,
      enableaccesscode: `1${formData.reports ? "1" : "0"}${
        formData.assessments ? "1" : "0"
      }${formData.setup ? "1" : "0"}`,
      signintypeid: parseInt(formData.signintypeid),
    };

    try {
      const response = await fetchUtility(bodyData, "createupdateusr");
      await fetchOrganizations();
      setModalMessage(JSON.stringify(response, null, 2));
      setShowModal(true);
      if (mode === "create") {
        clearForm();
      }
    } catch (error) {
      setModalMessage(`Error: ${error.message}`);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log('handleChange:', { name, value, type, checked });

    if (name === 'SigninType') {
      setFormData(prevState => ({
        ...prevState,
        signintypeid: value
      }));
    } 
    else if (name === 'organization') {
      const selectedOrg = responseOrganizations.find(org => org.clientid.toString() === value);
      setFormData(prevState => ({
        ...prevState,
        organizationid: value,
        organization: selectedOrg ? selectedOrg.client : ''
      }));
    } 
    else if (type === 'checkbox') {
      setFormData(prevState => ({
        ...prevState,
        [name]: checked
      }));
    } 
    else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }

    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: undefined }));
    }
  };

  const handleUserSelect = useCallback(
    (userid) => {
      setSelectedUserId(userid);
      if (mode === "create") {
        setMode("modify");
      }
      fetchUserInfo(userid);
    },
    [mode]
  );

  const fetchUserInfo = useCallback(
    async (userId) => {
      if (!userId) return;

      const bodyData = {
        transactiontype: "getUserInfo",
        username: username,
        userid: userid,
        manageuserid: userId,
        token: token,
      };

      try {
        const responseData = await fetchUtility(bodyData, "manageuser");
        if (responseData && responseData[0]) {
          const enableaccesscode = responseData[0].enableaccesscode;
          let reportsaux, assessmentsaux, setupaux;
          if (enableaccesscode.length === 4) {
            reportsaux = enableaccesscode.substring(1, 2) === "1";
            assessmentsaux = enableaccesscode.substring(2, 3) === "1";
            setupaux = enableaccesscode.substring(3, 4) === "1";
          } else {
            reportsaux = false;
            assessmentsaux = false;
            setupaux = false;
          }

          console.log('User data fetched:', responseData[0]);

          setFormData(prevState => ({
            ...prevState,
            username: responseData[0].username || "",
            name: responseData[0].name || "",
            lastName: responseData[0].lastname || "",
            email: responseData[0].usremail || "",
            organization: responseData[0].client || "",
            organizationid: responseData[0].clientid?.toString() || "",
            reports: reportsaux,
            assessments: assessmentsaux,
            setup: setupaux,
            signintypeid: responseData[0].signintypeid?.toString() || "",
          }));

        } else {
          setModalMessage("Error: No se recibieron los datos esperados de la API");
          setShowModal(true);
        }
      } catch (error) {
        setModalMessage(`Error al obtener la información del usuario: ${error.message}`);
        setShowModal(true);
      }
    },
    [userid, username, token]
  );

  const fetchOrganizations = useCallback(async () => {
    const bodyData = {
      transactiontype: "getClients",
      userid: userid,
      username: username,
      token: token,
    };

    try {
      const response = await fetchUtility(bodyData, "clientmanagement");
      if (Array.isArray(response)) {
        setResponseOrganizations(response);
      } else {
        setModalMessage("La respuesta de organizaciones no es un array");
        setShowModal(true);
        setResponseOrganizations([]);
      }
    } catch (error) {
      setModalMessage(`Error al obtener las organizaciones: ${error.message}`);
      setShowModal(true);
      setResponseOrganizations([]);
    }
  }, [userid, username, token]);

  const fetchSigninTypes = useCallback(async () => {
    const bodyData = {
      transactiontype: "getSignintype",
      userid: userid,
      username: username,
      token: token,
    };

    try {
      const response = await fetchUtility(bodyData, "manageuser");
      if (Array.isArray(response)) {
        setSigninTypes(response);
      } else {
        setModalMessage("La respuesta de tipos de inicio de sesión no es un array");
        setShowModal(true);
        setSigninTypes([]);
      }
    } catch (error) {
      setModalMessage(`Error al obtener los tipos de inicio de sesión: ${error.message}`);
      setShowModal(true);
      setSigninTypes([]);
    }
  }, [userid, username, token]);

  useEffect(() => {
    fetchOrganizations();
    fetchSigninTypes();
  }, [fetchOrganizations, fetchSigninTypes]);

  return (
    <div className={styles.manageUsersContainer}>
      <div className={styles.treeViewContainer}>
        <TreeView transactiontype="getUsers" onNodeSelect={handleUserSelect} />
      </div>

      <div className={styles.formContainer}>
        <div className={styles.radioButtons}>
          <label>
            <input
              type="radio"
              value="create"
              checked={mode === "create"}
              onChange={() => handleModeChange("create")}
            />
            Create User
          </label>
          <label>
            <input
              type="radio"
              value="modify"
              checked={mode === "modify"}
              onChange={() => handleModeChange("modify")}
            />
            Modify User
          </label>
          <label>
            <input
              type="radio"
              value="delete"
              checked={mode === "delete"}
              onChange={() => handleModeChange("delete")}
            />
            Delete User
          </label>
        </div>

        <form className={styles.formContent} onSubmit={handleSubmit}>
          <h2>Manage Users</h2>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            readOnly={mode === "delete"}
          />
          {errors.name && <span className={styles.error}>{errors.name}</span>}
          
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleChange}
            readOnly={mode === "delete"}
          />
          {errors.lastName && (
            <span className={styles.error}>{errors.lastName}</span>
          )}
          
          <input
            type="text"
            name="username"
            placeholder="Username"
            value={formData.username}
            onChange={handleChange}
            readOnly={mode === "delete"}
          />
          {errors.username && (
            <span className={styles.error}>{errors.username}</span>
          )}
          
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleChange}
            readOnly={mode === "delete"}
          />
          {errors.email && <span className={styles.error}>{errors.email}</span>}
          
          <select
            name="organization"
            value={formData.organizationid}
            onChange={handleChange}
            disabled={mode === "delete"}
          >
            <option value="">Select Organization ...</option>
            {responseOrganizations.map((org) => (
              <option key={org.clientid} value={org.clientid.toString()}>
                {org.client}
              </option>
            ))}
          </select>
          {errors.organization && (
            <span className={styles.error}>{errors.organization}</span>
          )}

          <select
            name="SigninType"
            value={formData.signintypeid}
            onChange={handleChange}
            disabled={mode === "delete"}
          >
            <option value="">Signin type</option>
            {signinTypes.map((type) => (
              <option key={type.signintypeid} value={type.signintypeid.toString()}>
                {type.signintype}
              </option>
            ))}
          </select>
          {errors.SigninType && (
            <span className={styles.error}>{errors.SigninType}</span>
          )}

          <div className={styles.checkboxGroup}>
            <label>
              <input
                name="reports"
                type="checkbox"
                checked={formData.reports}
                onChange={handleChange}
                disabled={mode === "delete"}
              />
              Reports
            </label>
            <label>
              <input
                name="assessments"
                type="checkbox"
                checked={formData.assessments}
                onChange={handleChange}
                disabled={mode === "delete"}
              />
              Assessments
            </label>
            <label>
              <input
                name="setup"
                type="checkbox"
                checked={formData.setup}
                onChange={handleChange}
                disabled={mode === "delete"}
              />
              Setup
            </label>
          </div>

          <input
            type="submit"
            value={
              mode === "create"
                ? "Create User"
                : mode === "modify"
                ? "Update User"
                : "Delete User"
            }
          />
        </form>
      </div>
      <ModalMessage
        show={showModal}
        onHide={handleCloseModal}
        message={modalMessage}
      />
    </div>
  );
};

export default ManageUsers;